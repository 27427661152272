export const SUPPORTED_CURRENCIES = ['USD', 'EUR'];
export const DEFAULT_CURRENCY = 'USD';

export const formatDisplayableCurrency = (
  value: number,
  locale: string,
  currency = 'USD'
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency ? currency : 'USD',
  }).format(value);
};
