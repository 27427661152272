import c from 'classnames';

type TooltipProps = {
  label: string;
  className?: string;
  html?: boolean;
};

export const Tooltip = ({
  label,
  className = '',
  html = false,
}: TooltipProps) => {
  return (
    <div
      className={c(
        'rounded-sm bg-grey-700 px-2.5 py-2.5 text-xs text-white',
        className
      )}
    >
      {html ? (
        <div
          className="[&_a]:text-blue"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        label
      )}
    </div>
  );
};
