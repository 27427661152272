import { Star, StarHalf } from 'phosphor-icons';
import { newArrayOfSize } from 'utils';

type StarsProps = {
  value: number;
  max?: number;
  width?: number;
  height?: number;
  ariaLabel?: string;
};

const MAX_DEFAULT = 5;
const WIDTH_DEFAULT = 25;
const HEIGHT_DEFAULT = 25;

export const Stars = ({
  value,
  ariaLabel = `${value} out of 5 stars`,
  max = MAX_DEFAULT,
  width = WIDTH_DEFAULT,
  height = HEIGHT_DEFAULT,
}: StarsProps) => {
  const stars = newArrayOfSize(max).map((_, index) => {
    const decimalPart = ((value * 10) % 10) / 10;
    const integerPart = Math.round(value - decimalPart);

    if (index < integerPart) {
      // Full star for each integer part
      return (
        <Star
          // index is okay to use as a key here since the items will never change order, and there is no other way to differentiate items
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={width}
          height={height}
          className="fill-current"
          data-test="star-full"
          weight="fill"
        />
      );
    } else if (index === integerPart) {
      // Apply rules for half star or full star based on decimal part
      if (decimalPart >= 0.3 && decimalPart <= 0.7) {
        return (
          <StarHalf
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            width={width}
            height={height}
            className="fill-current"
            data-test="star-half"
            weight="fill"
          />
        );
      } else if (decimalPart > 0.7) {
        return (
          <Star
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            width={width}
            height={height}
            className="fill-current"
            data-test="star-full"
            weight="fill"
          />
        );
      }
    }
    // Empty star for remaining indexes
    return (
      <Star
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        width={width}
        height={height}
        className="fill-current"
        data-test="star-empty"
        aria-hidden
      />
    );
  });

  return (
    <div
      role="img"
      aria-label={ariaLabel}
      className="flex"
      data-test="stars-rating"
      data-test-score={value}
    >
      {stars}
    </div>
  );
};
