import c from 'classnames';

import { interpolateStringWithData } from 'utils';
import { Link } from '../Link/Link';

type UserAssetsCollectionProps = {
  teaserImages: string[];
  link: string;
  title: string;
  length: number;
  numberOfAssetsString?: string;
};

const MAX_IMAGES = 4;

export const UserAssetsCollection = ({
  teaserImages,
  link,
  title,
  length,
  numberOfAssetsString,
}: UserAssetsCollectionProps) => {
  const teaserImageClasses =
    'w-full h-[6.25rem] bg-center bg-no-repeat bg-cover';

  return (
    <Link
      data-test="user-asset-collection"
      href={link}
      className="sm:w-1/2 mb-6 w-full px-2 md:w-1/3 lg:w-1/4"
    >
      <div className="grid grid-cols-2 gap-1 overflow-hidden rounded-md">
        {teaserImages.slice(0, MAX_IMAGES).map((image) => {
          return (
            <div
              data-test="user-asset-collection-teaser-image"
              className={teaserImageClasses}
              style={{ backgroundImage: `url('${image}')` }}
              key={image}
            ></div>
          );
        })}
        {teaserImages.length < MAX_IMAGES
          ? Array.from<number>({
              length: MAX_IMAGES - teaserImages.length,
            }).map((_, index) => {
              return (
                <div
                  data-test="user-asset-collection-placeholder"
                  className={c(teaserImageClasses, 'bg-grey-200')}
                  // index is okay to use as a key here since the items will never change order, and there is no other way to differentiate items
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                ></div>
              );
            })
          : null}
      </div>
      <div className="mt-4 text-md">{title}</div>
      <div className="mt-1 text-sm text-grey-600">
        {numberOfAssetsString
          ? interpolateStringWithData(numberOfAssetsString, {
              number: String(length),
            })
          : `${length} assets`}
      </div>
    </Link>
  );
};
